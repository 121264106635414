import React, {useEffect, useState} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import MyProjects from '../../Components/ClosedMyActiveProjects/MyProjects'
import ActiveProjects from '../../Components/ClosedMyActiveProjects/ActiveProjects'
import ClosedProjects from '../../Components/ClosedMyActiveProjects/ClosedProjects'
import { fetchingData }  from '../../Fetch'
import { PROJECTS } from '../../Consts'
import { useSelector} from 'react-redux'


const AllProjects = () => {

    const [activeProjects, setActiveProjects] = useState([])
    const [closedProjects, setClosedProjects] = useState([])
    const [myProjects, setMyProjects] = useState([])

    const token = useSelector(state => state.AccessTokenReducer.token)
    
    useEffect(() => {          
        const fetchingProjects = async(url) => {
            try{   
                const REQUESTSOPTIONS = {
                    method: 'GET',
                    headers: {"Authorization": `Bearer ${token}`}
                }   
                const res = await fetchingData(url, REQUESTSOPTIONS)    
                    setActiveProjects(res.activeProjects)
                    setMyProjects(res.myProjects)
                    setClosedProjects(res.closedProjects)
                }catch(e){
                    console.log(e);
                }
            }
        fetchingProjects(PROJECTS)
    },[token])
    

    return (
        <div style={styles.allprojBlock}> 
        
            <div style={styles.allprojActivated}>
                <MyProjects myProjects={myProjects}/>
                <ActiveProjects activeProjects={activeProjects}/>
            </div>
            
            <ClosedProjects closedProjects={closedProjects}/>
        
        </div>

    )
}

export default AllProjects

const styles = {
    allprojBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "16.5vw",
        height: "100vh",
        background: "rgb(255, 255, 255)"
    },

    allprojActivated: {
        marginTop: '2.5vh',
        height: 'fit-content',
        width: '100%',
        boxShadow: '1px 3px 6px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: '3px'
    }

}