import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore }  from 'redux'
import { rootReducer } from './Reducers/rootReducer';
import Auth0ProviderWithHistory from './auth0-provider-with-history'
import './index.sass';



const store = createStore(rootReducer)

ReactDOM.render(
 
 <Router>
  {/* Auth0 */}
    <Auth0ProviderWithHistory>
     {/* Redux */}
      <Provider store={store}>
  
        <App />
  
      </Provider>
  
    </Auth0ProviderWithHistory>
 
  </Router>,

 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

