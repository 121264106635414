import {MarkedCheckbox, Empty} from '../../jsxIcons/icons'
import { useDispatch, useSelector } from "react-redux"
import './style.sass'
const CurrentPosition = () => {

    const dispatch = useDispatch()
    const currentPosition  = useSelector(state => state.CurrentPositionReducer.currentPosition)
    
    const newPosition = () => {
        if(currentPosition) {dispatch({type:'FALSE'})}
        else{dispatch({type:'TRUE'})}
    }

    return(
        <div className="curPos-wrapper"
         onClick={() => newPosition()}
        >
        { currentPosition ? 
         <div className='curPos-checkbox'>
              <MarkedCheckbox />    
         </div>
             :
         <div className="curPos-checkbox" >
             <Empty/>
         </div>
         }
         <div className="curPos-title"> Current Position </div>
     </div>
    )
} 

export default CurrentPosition

