import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import './style.sass'

const ProjectShower = () => {
  
  const navbar = ['Overview','FB Auditory','FB Posts','Reports', 'Hypotesis', 'Label', 'Label']
  const [navbarState , setNavbarState] = React.useState(0)
  
  const {user} = useAuth0()
  
  return (
      <div className='proj-wrapper'>
          
          <div className='proj-navbar'>  
            {navbar.map((elem,index) => {
              return(
                <div key={index} className={ navbarState === index ? 'proj-nav-common proj choosed' : 'proj-nav-common proj'}
                  onClick={() => setNavbarState(index)}>
                  <div className='proj-nav-names'>
                    {elem}
                  </div>
                </div>
              )
            })}
          </div>
         
          <div className='shower-teams'>
                <div className='team'>
                  <div className='shower-team-headline'>TEAM</div>
                  <div className='shower-teamate'>
                    <div className='teamate-pic'>
                    { user && 
                      <img src={user.picture} alt='userPicture' className='teamate-img'/>}
                    </div>
                    <div className='teamate-name'>{user.name || 'Name'}</div>
                    <div className='teamate-role'>Role</div>
             
                  </div>
                </div>          
          </div>
    </div> 
   )
}

export default ProjectShower
