
// Company link
export const COMPANIES = '/fapi/Companies/'
export const COMPANIESSEARCH = "/fapi/Companies/search?name="

// Job link
export const JOBS = "/fapi/JobTitles/"
export const JOBSSEARCH = "/fapi/JobTitles/search?name="

// People link
export const POSTREQUEST = '/fapi/Profiles/search'

//Projects link
export const PROJECTS = 'https://api.modumops.com/api/Projects'
