import React, {useEffect, useState} from 'react'
import FacebookSearch from './Viewers/FacebookSearch';
import Projects from './Viewers/Projects';
import NavBar from './Viewers/NavBar'
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import LoaderPage from './Components/Loader/LoaderPage';

function App() {
  const dispatch = useDispatch()
  
  const { 
      isLoading,
      error,
      isAuthenticated,
      loginWithRedirect,
      getAccessTokenSilently} = useAuth0()
      
  const loader = useSelector(state => state.LoaderOn.loader)
  const AccessToken = getAccessTokenSilently().then(token => dispatch({type:'NEW_TOKEN', payload:token}))
    console.log(AccessToken,'PROJ'); 
  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect()
  })
  
  if(isLoading){
    return <div>Loading....</div>
  }
  if(error){
    return <div>Oops....{error.message}</div>
  }
  
  return (
    isAuthenticated 
      &&
    <div style={{display:'flex'}}>
      <Router>
      { loader 
          &&
       <LoaderPage/>  }
        
        <NavBar/>
          
        <Switch>
          
          <Route exact path='/' commponent={''}/>
          
          <Route exact path='/projects' component={Projects}/>
          
          <Route exact path='/facebook-search' component={FacebookSearch}/>
         
        </Switch>
        
      </Router>

    </div>
  )
}

export default App;