import React, { useEffect, useState } from 'react'
import { POSTREQUEST } from '../../Consts'
import { useSelector, useDispatch } from 'react-redux'
import { fetchingData } from '../../Fetch'
import Pagination from '../../Components/Pagination/Pagination'
import Loader from 'react-loader-spinner'
import './style.sass'


function UsersShower() {
  
  // Navbar
  const navbar = ['NAME', 'JOBS', 'COMPANY', 'DATE']

  // From Redux
  const dispatch = useDispatch()
  
  const pageNum = useSelector(state => state.PagesReducer.pageNum)
  const currentPosition = useSelector(state => state.CurrentPositionReducer.currentPosition)
  const IdJob = useSelector(state => state.IdJobReducer.IdJob)
  const IdComp = useSelector(state => state.IdCompReducer.IdComp)
  const token = useSelector(state => state.AccessTokenReducer.token)

    //  Rerecord
    const [oldCurrentPosition, setOldCurrentPosition] = useState(true)
    const [oldIdJob, setOldIdJob] = useState([])
    const [oldIdComp, setOldIdComp] = useState([])
    const [oldPage, setOldPage] = useState([])

  // Getting  users data
  const [data, setData] = useState({
    array: [],
    id: '',
    pagesCount: undefined,
  })

  if(data.array.length){
    dispatch({type:'LOADER_OFF'})
  }

  useEffect(() => {
    if (  (IdJob.length !== oldIdJob.length) ||
          (IdComp.length !== oldIdComp.length) || 
          (currentPosition !== oldCurrentPosition) ) {
      setOldIdJob([...IdJob])
      setOldIdComp([...IdComp])
      setOldCurrentPosition(currentPosition)
      dispatch({type:'DEFAULT_PAGE'})
      dispatch({type:'LOADER_ON'}) 
      data.id = ''
      data.array = []
    }
    if(pageNum !== oldPage ){
      data.array = []
      setOldPage(pageNum)
    }

    const fetchingUsers = async (url) => {
      try{
    
        const REQUESTSOPTIONS = {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(
            {
              "Companies": IdComp,
              "JobTitles": IdJob,
              "SearchId": data.id,
              "PageNumber": pageNum,
              "OnlyCurrent": currentPosition
            }
          )
        }
        const res = await fetchingData(url, REQUESTSOPTIONS)

        const resultsOfSearch = res.profiles.map(props => {
          const personalInformation = [{}]
          const companyUrl = []

          for (let i = 0; i < props.jobInfo.length; i++) {
            companyUrl.push(props.jobInfo[i].companyUrl)
            personalInformation.push({
              job: props.jobInfo[i].jobTitle,
              comp: props.jobInfo[i].company,
              date: props.jobInfo[i].startDateText + ' - ' + props.jobInfo[i].endDateText
            })
          }
    
          return {
            nickname: props.nickname,
            avatar: props.avatarUrl,
            profileUrl: props.profileUrl,
            fullName: props.fullName,
            companyUrl: companyUrl,
            personalInformation: personalInformation
          }
        })
        setData({ array: resultsOfSearch, id: res.id, page: res.page, pagesCount: res.pagesCount })
      }catch(e){
          console.log(e);
    }
    }
    fetchingUsers(POSTREQUEST)
  }, [IdJob, IdComp, data.searchId, pageNum, currentPosition,token])



  return (
    <div className='search-wrapper'>
     <div className='search-wholestuff'>

      <div className='search-navbar'>
        {navbar.map((elem, index) => {
          return (
            <div key={index} className="search-nav-common">
              <div className='search-nav-names'>
                {elem}
              </div>
            </div>
          )
        })}
      </div>

      <div className='serach-results'>
        { data.array.length === 0 
            ?
            data.pagesCount === 0 
              ? 
              <div className='search-spiner'>
                No people
              </div>
              : 
              <div className='search-spiner'>
                <Loader
                  type="Puff"
                  color="rgb(168, 255, 203)"
                  height={100}
                  width={100}
                  />
      
                {/* Loading... */}
              </div>           
                 
            :
            data.array.map((props, index) => {
              return ( 
                <div key={index} className='search-list'>
                  <div id={index} className='search-elems-wrapper'>

                    {/* Name block */}
                    <div className='search-name-block'>
                      {/* <img> */}
                      
                      <img alt='avatar' src={props.avatar} className='search-avatar'/>
                
                      <div className='search-fullName'>
                        <a href={props.profileUrl} style={{ textDecoration: 'none', color: 'black' }}>
                          {props.fullName || props.nickname}</a>
                      </div>
                    </div>

                    {/* Information block */}
                    <div className='search-elems'>
                      {props.personalInformation.map((e, index) => {
                        return (
                          <div key={index} className='search-elems-childs'>
                            <div className='search-each-childs'>{e.job}</div>
                            <div className='search-each-childs'>
                              <a href={props.companyUrl[index - 1]}
                                style={{ textDecoration: 'none', color: 'black' }}>{e.comp}</a>
                            </div>
                            <div className='search-each-childs'>{e.date}</div>
                          </div>
                        )
                      })}
                    </div>

                  </div>
                </div>
              )
            })}
        </div>

      </div>
      {/* Pagination */}
      <Pagination pagesCount={data.pagesCount}/>
    </div>
  )
}

export default UsersShower
