// Black panel


export const ProjectsIcon = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z" fill="#3F3E3E"/>
        <path d="M22 10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V1.99999C13.25 1.69999 13.43 1.41999 13.71 1.30999C13.99 1.18999 14.31 1.25999 14.53 1.46999L22.53 9.46999C22.74 9.67999 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.80999V5.99999C14.75 8.57999 15.42 9.24999 18 9.24999H20.19L14.75 3.80999Z" fill="#3F3E3E"/>
        <path d="M13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75Z" fill="#3F3E3E"/>
        <path d="M11 17.75H7C6.59 17.75 6.25 17.41 6.25 17C6.25 16.59 6.59 16.25 7 16.25H11C11.41 16.25 11.75 16.59 11.75 17C11.75 17.41 11.41 17.75 11 17.75Z" fill="#3F3E3E"/>
        </svg>
        
        )
    }
export const Dollar = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4002 17.4201H10.8902C9.25016 17.4201 7.92016 16.0401 7.92016 14.3401C7.92016 13.9301 8.26016 13.5901 8.67016 13.5901C9.08016 13.5901 9.42016 13.9301 9.42016 14.3401C9.42016 15.2101 10.0802 15.9201 10.8902 15.9201H13.4002C14.0502 15.9201 14.5902 15.3401 14.5902 14.6401C14.5902 13.7701 14.2802 13.6001 13.7702 13.4201L9.74016 12.0001C8.96016 11.7301 7.91016 11.1501 7.91016 9.36008C7.91016 7.82008 9.12016 6.58008 10.6002 6.58008H13.1102C14.7502 6.58008 16.0802 7.96008 16.0802 9.66008C16.0802 10.0701 15.7402 10.4101 15.3302 10.4101C14.9202 10.4101 14.5802 10.0701 14.5802 9.66008C14.5802 8.79008 13.9202 8.08008 13.1102 8.08008H10.6002C9.95016 8.08008 9.41016 8.66008 9.41016 9.36008C9.41016 10.2301 9.72016 10.4001 10.2302 10.5801L14.2602 12.0001C15.0402 12.2701 16.0902 12.8501 16.0902 14.6401C16.0802 16.1701 14.8802 17.4201 13.4002 17.4201Z" fill="#3F3E3E"/>
        <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V6C11.25 5.59 11.59 5.25 12 5.25C12.41 5.25 12.75 5.59 12.75 6V18C12.75 18.41 12.41 18.75 12 18.75Z" fill="#3F3E3E"/>
        <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#3F3E3E"/>
        </svg>  
        )
    }
    
export const  FacebookIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.75 22.75H10.25V15.5H9.29999C8.71999 15.5 8.25 15.03 8.25 14.45V12.55C8.25 11.97 8.71999 11.5 9.29999 11.5H10.25V9C10.25 6.93 11.93 5.25 14 5.25H16.7C17.28 5.25 17.75 5.71999 17.75 6.29999V8.70001C17.75 9.28001 17.28 9.75 16.7 9.75H14.75V11.5H16.63C16.95 11.5 17.24 11.64 17.44 11.88C17.64 12.12 17.72 12.44 17.66 12.75L17.28 14.65C17.18 15.14 16.75 15.49 16.25 15.49H14.75V22.75ZM11.75 21.25H13.25V14H15.89L16.09 13H13.26V9.29999C13.26 8.71999 13.73 8.25 14.31 8.25H16.26V6.75H14C12.76 6.75 11.75 7.76 11.75 9V13H9.75V14H11.75V21.25Z" fill="#3F3E3E"/>
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill="#3F3E3E"/>
        </svg>
    )
}
export const  ProfileIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.1205 14.3713C12.1005 14.3713 12.0705 14.3713 12.0505 14.3713C12.0205 14.3713 11.9805 14.3713 11.9505 14.3713C9.68047 14.3018 7.98047 12.5429 7.98047 10.3767C7.98047 8.17066 9.79047 6.37207 12.0105 6.37207C14.2305 6.37207 16.0405 8.17066 16.0405 10.3767C16.0305 12.5529 14.3205 14.3018 12.1505 14.3713C12.1305 14.3713 12.1305 14.3713 12.1205 14.3713ZM12.0005 7.85268C10.6005 7.85268 9.47047 8.98549 9.47047 10.3667C9.47047 11.7281 10.5405 12.8311 11.9005 12.8808C11.9305 12.8708 12.0305 12.8708 12.1305 12.8808C13.4705 12.8112 14.5205 11.7181 14.5305 10.3667C14.5305 8.98549 13.4005 7.85268 12.0005 7.85268Z" fill="#3F3E3E"/>
        <path d="M11.9998 23.533C9.30984 23.533 6.73984 22.5393 4.74984 20.7308C4.56984 20.5718 4.48984 20.3333 4.50984 20.1048C4.63984 18.9223 5.37984 17.8193 6.60984 17.0044C9.58984 15.0369 14.4198 15.0369 17.3898 17.0044C18.6198 17.8292 19.3598 18.9223 19.4898 20.1048C19.5198 20.3433 19.4298 20.5718 19.2498 20.7308C17.2598 22.5393 14.6898 23.533 11.9998 23.533ZM6.07984 19.906C7.73984 21.2873 9.82984 22.0425 11.9998 22.0425C14.1698 22.0425 16.2598 21.2873 17.9198 19.906C17.7398 19.2999 17.2598 18.7136 16.5498 18.2366C14.0898 16.607 9.91984 16.607 7.43984 18.2366C6.72984 18.7136 6.25984 19.2999 6.07984 19.906Z" fill="#3F3E3E"/>
        <path d="M12 23.5334C6.07 23.5334 1.25 18.7438 1.25 12.8512C1.25 6.95855 6.07 2.16895 12 2.16895C17.93 2.16895 22.75 6.95855 22.75 12.8512C22.75 18.7438 17.93 23.5334 12 23.5334ZM12 3.65949C6.9 3.65949 2.75 7.78332 2.75 12.8512C2.75 17.919 6.9 22.0428 12 22.0428C17.1 22.0428 21.25 17.919 21.25 12.8512C21.25 7.78332 17.1 3.65949 12 3.65949Z" fill="#3F3E3E"/>
        </svg>
    )
}

export const  SignOutIcon = () => {
    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 21.3525C4.5 21.2436 4.61605 21.0731 4.85714 21.0731H17.7143C19.3675 21.0731 20.7857 19.8485 20.7857 18.2349V4.20601C20.7857 2.5924 19.3675 1.36785 17.7143 1.36785H4.85714C4.61605 1.36785 4.5 1.1973 4.5 1.08847C4.5 0.979631 4.61605 0.809082 4.85714 0.809082H17.7143C19.8497 0.809082 21.5 2.37473 21.5 4.20601V18.2349C21.5 20.0662 19.8497 21.6319 17.7143 21.6319H4.85714C4.61605 21.6319 4.5 21.4613 4.5 21.3525Z" fill="#FDFEFF" stroke="#787878"/>
        <path d="M13.2739 12.2286L10.2036 15.0317C9.92511 15.2865 9.92511 15.7046 10.2036 15.9594C10.4821 16.2142 10.939 16.2142 11.2175 15.9594L15.5017 12.0391C15.7801 11.7843 15.7801 11.3662 15.5017 11.1114L11.2175 7.19111C10.939 6.9363 10.4821 6.9363 10.2036 7.19111C9.92511 7.44593 9.92511 7.86404 10.2036 8.11886L13.2739 10.9219H0.714035C0.321316 10.9219 0 11.2159 0 11.5753C0 11.9346 0.321316 12.2286 0.714035 12.2286H13.2739Z" fill="#787878"/>
        </svg>
    )
}

// White Left panel
export const  Arrow = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.76429 6.23569L4.90236 9.09762C4.77219 9.2278 4.77219 9.43885 4.90236 9.56903L5.37129 10.0379C5.50133 10.168 5.71214 10.1681 5.84236 10.0383L8 7.88666L10.1576 10.0383C10.2879 10.1681 10.4987 10.168 10.6287 10.0379L11.0976 9.56903C11.2278 9.43885 11.2278 9.2278 11.0976 9.09762L8.2357 6.23569C8.10552 6.10552 7.89447 6.10552 7.76429 6.23569Z" fill="#181C20" fillOpacity="0.7"/>
        </svg>
        
    )
}
export const  MarkedCheckbox = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z" fill="#0074AB"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 16.42L7 12.42L8.41 11.01L11 13.59L16.59 8L18 9.42L11 16.42Z" fill="white"/>
    </svg>  
    )
}
export const  Loupe = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3534 18.6476C19.5487 18.4523 19.5487 18.1357 19.3535 17.9404L14.314 12.9C15.403 11.504 16 9.79895 16 8C16 5.86304 15.167 3.85596 13.656 2.34399C12.145 0.832031 10.137 0 8 0C5.86304 0 3.854 0.832031 2.34399 2.34399C0.832031 3.85596 0 5.86304 0 8C0 10.137 0.832031 12.146 2.34399 13.656C3.854 15.168 5.86304 16 8 16C9.79895 16 11.504 15.4041 12.9 14.3149L17.9395 19.3545C18.1348 19.5497 18.4513 19.5497 18.6466 19.3545L19.3534 18.6476ZM12.2419 12.243C11.109 13.376 9.60205 14 8 14C6.39697 14 4.89099 13.376 3.75806 12.243C2.62402 11.11 2 9.60303 2 8C2 6.39795 2.62402 4.89099 3.75806 3.75806C4.89099 2.62402 6.39697 2 8 2C9.60205 2 11.109 2.62402 12.2419 3.75806C13.376 4.89099 14 6.39795 14 8C14 9.60303 13.376 11.11 12.2419 12.243Z" fill="black" fillOpacity="0.65"/>
        </svg>        
    )
}

export const  ArrUp = () => {
    return (
        <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.64648 0.353516L0.353638 4.64636C0.158325 4.84167 0.158325 5.1582 0.353638 5.35352L1.05701 6.05688C1.25208 6.25195 1.56824 6.2522 1.76355 6.05737L4 3.82716V12.5C4 12.7761 4.22386 13 4.5 13H5.5C5.77614 13 6 12.7761 6 12.5V3.82716L8.23645 6.05737C8.43188 6.2522 8.74805 6.25195 8.94312 6.05688L9.64648 5.35352C9.8418 5.1582 9.8418 4.84167 9.64648 4.64636L5.35364 0.353516C5.15833 0.158203 4.8418 0.158203 4.64648 0.353516Z" fill="black" fillOpacity="0.9"/>
        </svg>       
    )
}
export const  DownUp = () => {
    return (
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.686935 4.64642L4.97978 0.353577C5.17509 0.158264 5.49162 0.158264 5.68694 0.353577L9.97978 4.64642C10.1751 4.84174 10.1751 5.15826 9.97978 5.35358L9.27641 6.05695C9.08134 6.25201 8.76518 6.25226 8.56975 6.05743L5.3333 2.83002L2.09685 6.05743C1.90154 6.25226 1.58537 6.25201 1.3903 6.05695L0.686935 5.35358C0.491623 5.15826 0.491623 4.84174 0.686935 4.64642Z" fill="black" fillOpacity="0.65"/>
        <path d="M0.686935 9.35345L4.97978 13.6464C5.17509 13.8416 5.49162 13.8416 5.68694 13.6464L9.97978 9.35345C10.1751 9.15826 10.1751 8.84161 9.97978 8.64642L9.27641 7.94305C9.08134 7.74799 8.76518 7.74774 8.56975 7.94257L5.3333 11.17L2.09685 7.94257C1.90154 7.74774 1.58537 7.74799 1.3903 7.94305L0.686935 8.64642C0.491623 8.84161 0.491623 9.15826 0.686935 9.35345Z" fill="black" fillOpacity="0.65"/>
        </svg>
              
    )
}

// Users panel (Rightest)
export const  ButtonFade = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z" fill="#8A99A8" fillOpacity="0.2"/>
        <path d="M12.9613 15.7054L16.5387 12.128C16.7014 11.9652 16.9652 11.9652 17.128 12.128L17.7141 12.7141C17.8767 12.8767 17.8769 13.1402 17.7145 13.303L15.025 16L17.7145 18.697C17.8769 18.8598 17.8767 19.1233 17.7141 19.2859L17.128 19.872C16.9652 20.0348 16.7014 20.0348 16.5387 19.872L12.9613 16.2946C12.7986 16.1319 12.7986 15.8681 12.9613 15.7054Z" fill="black" fillOpacity="0.9"/>
        </g>
        </svg>
              
    )
}
export const  ButtonBright = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z" fill="#8A99A8" fillOpacity="0.2"/>
        <path d="M19.0387 15.7054L15.4613 12.128C15.2986 11.9652 15.0348 11.9652 14.872 12.128L14.2859 12.7141C14.1233 12.8767 14.1231 13.1402 14.2855 13.303L16.975 16L14.2855 18.697C14.1231 18.8598 14.1233 19.1233 14.2859 19.2859L14.872 19.872C15.0348 20.0348 15.2986 20.0348 15.4613 19.872L19.0387 16.2946C19.2014 16.1319 19.2014 15.8681 19.0387 15.7054Z" fill="black" fillOpacity="0.9"/>
        </svg>        
    )
}
export const  GaryArrow = () => {
    return (
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.64645 6.05645L0.353554 1.76355C0.158292 1.56829 0.158292 1.25171 0.353554 1.05645L1.05694 0.353061C1.25201 0.157991 1.56821 0.157771 1.76355 0.352568L5 3.58L8.23645 0.352568C8.43179 0.157771 8.74799 0.157991 8.94306 0.353061L9.64645 1.05645C9.84171 1.25171 9.84171 1.56829 9.64645 1.76355L5.35355 6.05645C5.15829 6.25171 4.84171 6.25171 4.64645 6.05645Z" fill="black" fillOpacity="0.65"/>
        </svg>               
    )
}
export const  Dots = () => {
    return (
        <svg width="8" height="32" viewBox="0 0 8 32" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g opacity="0.5">
        <rect width="8" height="32" fill="url(#pattern2)"/>
        </g>
        <defs>
        <pattern id="pattern2" patternContentUnits="objectBoundingBox" width="0.5" height="0.125">
        <use xlinkHref="#image0_13839_40473" transform="scale(0.125 0.03125)"/>
        </pattern>
        <image id="image0_13839_40473" width="4" height="4" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAbSURBVHgBhcOxCQAAAMKw4v8/6+IqBgKDG/EEUjYCAaeDBwcAAAAASUVORK5CYII="/>
        </defs>
        </svg>       
    )
}

export const  Empty = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20H5C4.44771 20 4 19.5523 4 19V5Z" stroke="black" strokeOpacity="0.65" strokeWidth="2"/>
        </svg>     
    )
}