import './style.sass'

const ActiveProjects = ({activeProjects}) => {
    return(
        <div className='projects-wrapper'>
            <div className='projects-headline'>
                Active projects
            </div> 
            <div className='projects-results'>
                {activeProjects.map((elem, index)=> {
                    return(
                        <div key={index} className='projects-results-lines'>
                            {elem.title}
                        </div>
                    )
                })
            }
        </div>
    </div>   
    )
}

export default ActiveProjects