import React from 'react'
import Loader from 'react-loader-spinner'
import'./style.sass'

const LoaderPage = () => {
  return(
    <div className='Loader-background'>
      <div className='loader'>
      <Loader
        type="TailSpin"
        color="#00BFFF"
        height={100}
        width={100}
            />
      </div>
    </div>
  )
}

export default LoaderPage