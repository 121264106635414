import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import {getConfig} from './config'

const Auth0ProviderWithHistory = ({ children }) => {
    
    const history = useHistory()
    
    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname)
    }
    const config = getConfig();

    const providerConfig = {
        domain: config.domain,
        clientId: config.clientId,
        ...(config.audience ? { audience: config.audience } : null),
        redirectUri: window.location.origin,
        onRedirectCallback,
    };

    return(
        <Auth0Provider
            {...providerConfig}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            cacheLocation="localstorage">
                {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory