import JobTitles from '../Components/CompAndJob/JobTitles'
import Company from '../Components/CompAndJob/Company'
import UsersShower from '../Containers/UsersShower/UsersShower'
import CurrentPosition from '../Components/CurrentPosition/CurrentPosition'

const All = () => {
    return (
        <div style={{display:'flex'}}>
            <div style={styles}>
                <CurrentPosition/>
                <JobTitles/>
                <Company/>  
            </div>         
            <UsersShower/>
        </div>
    )
}

export default All

const styles = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '16.5vw',
    height: '100vh',
    background: 'rgb(255, 255, 255)',
 }