const defaultToken = {
    token: []
}
export const AccessTokenReducer = (state = defaultToken, action) => {
    switch (action.type) {
        case 'NEW_TOKEN' :
            return {...state, token: action.payload}
        default:
            return state
    }
}