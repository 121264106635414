import React from 'react'
import {ButtonFade, ButtonBright} from '../../jsxIcons/icons'
import { useDispatch, useSelector } from 'react-redux'
import './style.sass'

const Pagination = ({pagesCount}) => {

    const dispatch = useDispatch()
    const pageNum = useSelector(state => state.PagesReducer.pageNum)
    
    const delPage = () => {
        if (pageNum !== 1){dispatch({type:'DECREMENT_PAGE'})}
      }
      const addPage = () => {
        if (pageNum !== pagesCount) {dispatch({type:'INCREMENT_PAGE'})} 
      }
    
    return (
     ( (pagesCount !== undefined ) && (pagesCount !== 1 ) && (pagesCount !== 0 ) ) 
            &&
        <div className='pag-pagination'>

            <div style={{ display: 'flex', gap: '3px' }}>
                
                <div className='left-button'
                    onClick={() => delPage()}>
                    {pageNum === 1 ?
                        <ButtonFade />
                    :
                    <div style={{ transform: 'rotate(180deg)' }}>
                        <ButtonBright />
                    </div>}
                </div>
                <div className='right-button'
                    onClick={() => addPage()}>
                    {pageNum === pagesCount ?
                    <div style={{ transform: 'rotate(180deg)' }}>
                        <ButtonFade />
                    </div>
                    :
                        <ButtonBright />}
                </div>
            </div>
            <div className='pag-quantity'>{pageNum || 1}-{pagesCount}</div>
            
      </div> 
    )
}

export default Pagination
