const defaultIdJob = {
    IdJob: []
}
export const IdJobReducer = (state = defaultIdJob, action ) => {
    switch (action.type) {
        case 'ADD_ID_JOB' :
            return {
                ...state,  
                IdJob : [...state.IdJob, action.payload ]
            }
        case 'DEL_ID_JOB' :
            return{  ...state,
                IdJob : state.IdJob.slice(0, action.payload).concat(state.IdJob.slice(action.payload+1)) 
                }
        default:
            return state
    }
}