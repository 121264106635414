import React,{useState, useEffect} from "react";
import { fetchingData }  from '../../Fetch'
import { JOBS, JOBSSEARCH } from '../../Consts'
import { useDispatch, useSelector } from "react-redux";
import { Arrow, MarkedCheckbox, Loupe } from "../../jsxIcons/icons";
import './style.sass'


export default function JobTitles(){    
          
    // Under search chosen jobs shcedule 
    const [getTitle, setGetTitle] = useState([])
    
    function getTitleFunction({id, title}) {
        id = Number(id)
        if(!IdJob.includes(id)){
            const obj = {
                id,
                title
            }
            getTitle.push(obj)
        }
    }
    
// Redux
    const dispatch = useDispatch()
    const nameOfElement = useSelector(state => state.reducer.nameOfElement)
    const currentPosition = useSelector(state => state.CurrentPositionReducer.currentPosition)
   
     // Open/Close
    function createnameOfElement() {
        if(nameOfElement !== 'JobTitles') {dispatch({type:"JobTitles"})}
        else{dispatch({type:'defaultState'})}
    }
    

    const IdJob = useSelector(state => state.IdJobReducer.IdJob)
    
    // Adding in redux state
    const addIdJob = (id) => {
        id = Number(id)
        if(!IdJob.includes(id)){
            dispatch({type:'ADD_ID_JOB', payload: id })
       }
    }

    // Deleting in redux state
    const delIdJob = (id) => {
        id = Number(id)
        dispatch({type:'DEL_ID_JOB', payload: IdJob.indexOf(id)})
    }
        

    const [job, setJob] = useState([])
    
// Getting data 

    const [searchJob, setSearchJob] = useState('')
    
    // Delete a search line 
    document.addEventListener('click', () => {
        setSearchJob('')   
    })

    const fetchingJobs = async(url) => {
        const res = await fetchingData(url)
        setJob(res)
    } 

    useEffect(() => {
        if(searchJob.length === 0){fetchingJobs(JOBS)}
        else{fetchingJobs(JOBSSEARCH + searchJob + `&onlyCurrent=${currentPosition}`)}   
    },[searchJob,currentPosition])

    // Show substring
    const lookingForJob = job.filter(j => {
        return j.title.toLowerCase().includes(searchJob.toLowerCase())
   })



    return(
        <div className="common-wrapper">
            <div className="common-block-e">
                <div className={`${nameOfElement === 'JobTitles'  ? 'common-left-block-opened' : 'common-left-block-e'}`}>
                   
                    {/* Header */}
                    <div className='common-clicker' onClick={() => createnameOfElement()}>
                        <div className="common-headline"> Job Titles </div>
                        <div className={`${ nameOfElement === 'JobTitles' ? 'common-Arrow down' : 'common-Arrow'  }`}>
                            <Arrow />
                        </div>
                    </div>

                    {/* Under Header */}
                    <div className='common-click-res'>
            
                        {/* input */}
                        <div  className='common-block-input'>
                           <div className="common-input-field">
                                <div className='common-Loupe'>
                                    <Loupe/>
                                </div>
                                <input id='input'  value={searchJob} placeholder='Search'
                                onChange={e => setSearchJob(e.target.value)}
                                className='common-input'/> 
                            </div>
                        </div>

                        {/* Under input */}
                        <div className="common-marked">
                        {getTitle.map((j, index) => {
                            return(
                                <div key={j.id} id={j.id} 
                                onClick={ _ => {
                                    delIdJob(j.id)
                                    setGetTitle(getTitle.slice(0, index).concat(getTitle.slice(index+1)))
                                }}
                                className="common-clicked-field" >
                                    <div> 
                                        <MarkedCheckbox/> 
                                    </div>
                                    <div className="common-marked-title">
                                        {j.title}
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    
                        {/* List of all jobs */}
                        { searchJob && nameOfElement === 'JobTitles'
                            &&
                        <div className='common-list'>
                            { lookingForJob.map( ({ id, title}) => 
                                <div key={id}
                                id={id}
                                className='common-title'
                                onClick={e => {
                                    getTitleFunction({id,title})
                                    addIdJob(e.target.id) 
                                    }}>
                                <div
                                id={id}
                                className="common-titleField">
                                    {title}
                                </div>
                            </div>  
                        )}
                        </div>
                        }                  
                    </div> 
                </div>
            </div>
        </div>                        
    )
}