import './style.sass'

const MyProjects = ({myProjects}) => {
    return(
        <div className='projects-wrapper' style={{borderTop:'0'}}>
            <div className='projects-headline'>
                My projects
            </div>
            
            <div className='projects-results'>
            {myProjects.map((elem, index) => {
                    return( 
                        <div key={index} className='projects-results-lines'>
                            {elem.title}
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MyProjects