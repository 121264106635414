import React from "react";
import './style.sass'

export default function NavBarBase() {

    return(
        <div className="NavBar-Base">
           <div className="NavBar-modumOps">
               ModumOps
           </div>
        </div>
    )
}