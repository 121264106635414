import NavBarBase from '../Components/NavBarBase/NavBar-Base';
import NavMenuUserBase from "../Components/NavMenu/NavMenuUserBase";

export default function Side() {
    return (
        
        <div style={styles}>
            <NavBarBase/>
            <NavMenuUserBase/>
        </div>
    )
}

const styles = {
    position: 'relative',
    width: '14.5vw',
    height: '100vh',
    background: '#F7F9FA',
    borderRight: '2px solid #D1D2D3',
}