import React from "react";
import { FacebookIcon, SignOutIcon, 
    Dollar, ProjectsIcon, ProfileIcon 
}from '../../jsxIcons/icons'
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import './style.sass'

export default function NavMenuUserBase() {
    
    const { logout, user } =  useAuth0()

    return(
        
        <div className="Nav-Menu-User-Base-wrapper">
            
            <div className="Nav-Menu-contexts">
                
                <NavLink to='projects'style={{textDecoration: 'none'}}>
                    <div className="Nav-Menu-list">
                        <div className="Nav-Menu-Icon">
                            <ProjectsIcon/>
                        </div>
                        <div className="list-str">
                            Projects
                        </div>
                    </div>
                </NavLink>

                <NavLink to='finances' style={{textDecoration: 'none'}}>
                    <div className="Nav-Menu-list">
                        
                        <div className="Nav-Menu-Icon">
                            <Dollar/>
                        </div>
                        <div className="list-str">
                            Finances
                        </div>
                    
                    </div>
                </NavLink> 

            </div>
            
            <div className="facebook">
                <NavLink to='facebook-search' style={{textDecoration: 'none'}}>
                    <div className="Nav-Menu-list">
              
                        <div className="Nav-Menu-Icon">
                            <FacebookIcon/>
                        </div>
                        <div className="list-str">
                            Facebook Search
                        </div>
                    
                    </div>
                </NavLink> 
            </div>
            
            <div className="Nav-Menu-contexts profiles">
                <NavLink to='profile' style={{textDecoration: 'none'}}>
                    <div className="Nav-Menu-list profList">
                        <div className="Nav-Menu-Icon">
                            <ProfileIcon/>
                        </div>
                        <div className="list-str">Profile</div>
                    </div>
                </NavLink>
            </div>
            
        { user &&
            
            <div className="Nav-Menu-signOut">
                <div className="Nav-Menu-avatar">
                    <img alt="avatar" src={user.picture} className="Nav-Menu-avatar"/>
                </div>
                
                <div className="userName">
                    {user.name}
                </div>
               
                <div className="signOut-str" onClick={() => logout({returnTo: window.location.origin})}>
                    <SignOutIcon/>
                </div>
            </div>
        }
           
           
           
        </div>
    )
}
