import ProjectsList from '../Containers/ProjectsList/ProjectsList'
import ProjectsShower from '../Containers/ProjectsShower/ProjectsShower'


const Projects = () => {
    return(
        <div style={{display:'flex'}}>
            <ProjectsList/>
            <ProjectsShower/>
        </div>
    )
}

export default Projects
