const defaultCurrentPosition = {
    currentPosition: true
}

export const CurrentPositionReducer = (state=defaultCurrentPosition, action) => {
    switch(action.type){
        case 'TRUE':
            return { ...state, currentPosition: true}
        case 'FALSE' :
            return { ...state, currentPosition: false}
        default:
            return state
    }
}