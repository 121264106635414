
// Get data 
export const fetchingData = async (url, reqtype) => {  
        try{
            const res = await fetch(url, reqtype)
            if(!res.ok) {
                console.error('Could not fetch', res.status)
                return false
            }

            return   await res.json()
            
        } catch(err) {
            console.error('Could not fetch', err.message)
            return false
        }
}  
