import {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Arrow, MarkedCheckbox, Loupe } from "../../jsxIcons/icons";
import { fetchingData }  from '../../Fetch'
import { COMPANIES, COMPANIESSEARCH } from '../../Consts'
import './style.sass'

export default function Company() {
    
    // Under search chosen companies shcedule 
    const [getTitle, setGetTitle] = useState([])
    
    function getTitleFunction({id, companyTitle}) {
        id = Number(id)
        if(!IdComp.includes(id)){
            const obj = {
                id,
                companyTitle
            }
            getTitle.push(obj)
        }
    }
     
    
// Redux beggining
    const dispatch = useDispatch()
    const nameOfElement = useSelector(state => state.reducer.nameOfElement)
    const currentPosition = useSelector(state => state.CurrentPositionReducer.currentPosition)
   
    // Open/Close
    function createnameOfElement() {
        if(nameOfElement !== 'Company') {dispatch({type:"Company"})}
        else{dispatch({type:'defaultState'})}
    }
    

    const IdComp = useSelector(state => state.IdCompReducer.IdComp)
    
    // Add Company id Redux
    const addIdCompany = (id) => {
        id = Number(id)
        if(!IdComp.includes(id)){
            dispatch({type:'ADD_ID_COMP', payload: id })
       }
    }

    // Delete Company id Redux
    const delIdCompany = (id) => {
        id = Number(id)
        dispatch({type:'DEL_ID_COMP', payload: IdComp.indexOf(id)})
    }
        

    const [comp, setComp] = useState([])
    
// Getting data 

    const [searchCompany, setSearchCompany] = useState('')

    // Delete a search line 
    document.addEventListener('click', () => {
        setSearchCompany('')
    })

    const fetchingComp = async(url) => {
        const res = await fetchingData(url)
        const comps = res.map(( {id, companyTitle} ) => {
            return{
                id,
                companyTitle
            }
        })
        setComp(comps)
    } 
    useEffect(() => {
        if(searchCompany.length===0){fetchingComp(COMPANIES)}
        else{fetchingComp(COMPANIESSEARCH + searchCompany + `&onlyCurrent=${currentPosition}`)}
    },[searchCompany,currentPosition])

    // Search substring
    const lookingForCompany = comp.filter(j => {
        return j.companyTitle.toLowerCase().includes(searchCompany.toLowerCase())
   })


    return(
        <div className="common-wrapper">
            <div className="common-block-e">
                <div className={`${nameOfElement === 'Company'  ? 'common-left-block-opened' : 'common-left-block-e'}`}>
                    
                    {/* Header */}
                    <div  className='common-clicker' onClick={() => createnameOfElement()}>
                        <div className='common-headline'>COMPANIES</div>
                        <div className={`${ nameOfElement === 'Company' ? 'common-Arrow down' : 'common-Arrow'}`}>
                            <Arrow />
                        </div>
                    </div>

                    {/* Under Header */}
                    <div className='common-click-res'>
                    
                        {/* Input */}
                        <div  className='common-block-input'>
                            <div className="common-input-field">
                                <div className="common-Loupe">
                                    <Loupe/>
                                </div>
                                <input 
                                id='input'
                                value={searchCompany}
                                placeholder='Search'
                                onChange={e => {                               
                                    setSearchCompany(e.target.value)}}
                                className='common-input'/>                                
                                </div> 
                            
                        </div> 

                        {/* Under input */}
                        <div className="common-marked">
                         {getTitle.map((j,index) => {
                            return(
                                <div key={j.id}
                                    className="common-clicked-field" 
                                    id={j.id} 
                                    onClick={_ => {
                                        delIdCompany(j.id)
                                        setGetTitle(getTitle.slice(0, index).concat(getTitle.slice(index+1)))
                                    }}>
                                    <div><MarkedCheckbox/></div>
                                    <div className="common-marked-title">
                                        {j.companyTitle}
                                    </div>
                                            
                                </div>
                            )
                         })}
                        </div>
                                           
                        {/* List of all Companies */}
                        { searchCompany  && nameOfElement === 'Company'
                            &&
                        <div className='common-list'>
                            { lookingForCompany.map( ({ id, companyTitle}) => 
                            <div key={id} 
                                id={id}
                                className='common-title'
                                style={{position:'relative', zIndex:'10'}}
                                onClick={e => {
                                    getTitleFunction({id, companyTitle})
                                    addIdCompany(e.target.id) 
                                    }}>
                                <div
                                id={id}
                                className="common-titleField">
                                    {companyTitle}
                                </div>
                            </div>  
                        )}
                        </div>
                        }                   
                    </div>  
                </div>
            </div>
        </div>   
    )
} 