import { reducer } from "./reducer";
import { IdCompReducer } from "./CompReducer";
import { IdJobReducer } from "./JobReducer";
import { PagesReducer } from "./PagesReducer";
import { CurrentPositionReducer } from "./CurrentPositionReducer";
import {AccessTokenReducer} from "./AccessTokenReducer"
import {LoaderOn} from './LoaderOn'
import { combineReducers } from "redux";


export const rootReducer = combineReducers({
     reducer,
     IdCompReducer,
     IdJobReducer,
     PagesReducer,
     CurrentPositionReducer,
     AccessTokenReducer,
     LoaderOn
})