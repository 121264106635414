import './style.sass'

const ClosedProjects = ({closedProjects}) => {
    return (
        <div className='projects-wrapper' style={{borderTop:'0'}}>
            <div className='projects-headline'>
                Closed projects
            </div>
            <div className='projects-results'>
                {closedProjects.map((elem, index) => {
                    return(
                        <div key={index} className='projects-results-lines'>
                            {elem.title}
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ClosedProjects