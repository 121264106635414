const defualtState = {
    nameOfElement: '0'
  }
export const reducer = (state = defualtState, action) => {
    switch(action.type) {
        case 'JobTitles':
            return{
                ...state, 
                nameOfElement:'JobTitles'
            }
        
        case 'Company':
            return{
                ...state,
                 nameOfElement:'Company'
                }
        
                case 'Projects':
                    return{
                        ...state,
                        nameOfElement:'Projects'
                    }
        case 'defaultState':
            return{
                ...state,
                nameOfElement:'defaultState'
            } 
        default:  
            return state
    }
  }