const defaultIdComp = {
    IdComp: []
}
export const IdCompReducer = (state = defaultIdComp, action) => {
    switch (action.type) {
        case 'ADD_ID_COMP' :
            return {
                ...state,  
                IdComp : [...state.IdComp, action.payload ]
            }
        case 'DEL_ID_COMP' :
            return{  ...state,
                IdComp : state.IdComp.slice(0, action.payload).concat(state.IdComp.slice(action.payload+1)) 
                }
        default:
            return state
    }
}